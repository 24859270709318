<template>
  <v-dialog v-model="dialog" max-width="410px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="clase"
        :color="color"
        :disabled="disabled"
        :loading="loading"
        :icon="icon_button"
        :title="title"
        :x-small="small"
        v-bind="attrs"
        v-on="on"
      >
        <template v-if="!icon_button">
          {{ nombre }}
        </template>
        <v-icon :small="small" :right="!icon_button">{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="font-weight-light" style="word-break: normal">{{ texto }}</v-card-title>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn color="success" class="mr-2" @click="confirmar">Sí</v-btn>
        <v-btn color="error" @click="dialog = false">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  props: {
    icon_button: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    nombre: {
      type: String,
      default: 'Guardar',
    },
    icon: {
      type: String,
      default: 'fas fa-save',
    },
    texto: {
      type: String,
      default: '¿Desea guardar los cambios realizados?',
    },
    color: {
      type: String,
      default: 'success'
    },
    clase: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    }
  },
  methods: {
    confirmar() {
      this.$emit('action')
      this.dialog = false
    }
  }
}
</script>