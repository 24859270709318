<template>
  <v-simple-table dense fixed-header height="180px" :style="readonly">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left" :style="readonly"><h3>Tareas y responsabilidades</h3></th>
          <th v-if="!view">
            <v-dialog
              v-model="dialog"
              width="400"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  icon
                  title="Añadir"
                  v-bind="attrs"
                  v-on="on"
                ><v-icon>fas fa-plus</v-icon></v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-form class="pt-6" ref="form" @submit.prevent="add">
                    <v-text-field
                      outlined
                      v-model.trim="funcion"
                      :rules="[rules.required]"
                      label="Nueva función principal"
                      validate-on-blur
                    ></v-text-field>
                    <div class="d-flex justify-end mb-n1 mt-n1">
                      <v-btn
                        color="error"
                        @click="dialog = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        type="submit"
                        color="success"
                        class="ml-3"
                      >
                        Añadir
                      </v-btn>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in funciones"
          :key="index"
        >
          <td>{{ index + 1 }}. {{ item }}</td>
          <td v-if="!view"><v-btn icon color="error" title="Quitar" @click="delete_funcion(index)"><v-icon small>fas fa-minus</v-icon></v-btn></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      dialog: false,
      funcion: '',
      rules: { required: value => !!value || 'Campo requerido' },
    }
  },
  props: {
    view: Boolean,
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  computed: {
    ...mapState('funciones', ['funciones']),
    readonly(){
      return [ this.view ? { 'background-color' : '#f0f0f0' } : {} ]
    },
  },
  methods: {
    ...mapActions('funciones', ['delete_funcion', 'add_funcion']),
    add() {
      if (this.$refs.form.validate()) {
        this.add_funcion(this.funcion)
        this.dialog = false
      }
    },
    close() {
      this.funcion = ''
      this.$refs.form.resetValidation()
    },
  }
}
</script>