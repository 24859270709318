<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel class="mb-2">
        <v-expansion-panel-header class="text-h5 font-weight-light">
          <div><v-icon left class="mb-1">fas fa-filter</v-icon>Filtro</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="form" @submit.prevent="get_puestos(filtro)">
            <v-row>
              <v-col cols="6" sm="3" md="2">
                <v-select
                  v-model="filtro.empresa"
                  :items="empresas_select"
                  clearable
                  label="Empresa"
                  @change="get_areas(filtro.empresa)"
                ></v-select>
              </v-col>
              <v-col cols="6" sm="3" md="2">
                <v-select
                  v-model="filtro.area"
                  :items="areas"
                  clearable
                  label="Área"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4" md="3">
                <v-text-field
                  v-model.trim="filtro.nombre"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="2">
                <v-switch
                  v-model="filtro.inhabilitado"
                  label="Inhabilitado"
                ></v-switch>
              </v-col>
              <v-col cols="1" class="d-flex align-center justify-start">
                <v-btn type="submit" color="info" elevation="3" :loading="load">Buscar<v-icon small right>fas fa-search</v-icon></v-btn>
                <div class="ml-6"><LimpiarFiltro /></div>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      :headers="headers"
      :items="puestos"
      class="elevation-1 mt-6"
      :loading="load"
    >
      <template v-slot:top>
        <div class="d-flex py-2 px-3">
          <v-spacer></v-spacer>
          <v-dialog
            :persistent="edit"
            v-model="dialog"
            max-width="800px"
            scrollable
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex align-start justify-end mt-n7">
                <v-btn
                  color="success"
                  fab
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span v-if="!edit" class="text-h5">Detalle</span>
                <span v-else class="text-h5">{{ formTitle }}</span>
                <v-spacer></v-spacer>
                <v-switch
                  v-if="editedIndex !== -1"
                  v-model="edit"
                  label="Editar"
                ></v-switch>
              </v-card-title>

              <v-card-text>
                <v-form ref="form" class="mt-3">

                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :readonly="!edit"
                        :filled="!edit"
                        v-model.trim="editedItem.nombre"
                        :rules="[rules.required]"
                        label="Nombre"
                        validate-on-blur
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-select
                        :readonly="!edit"
                        :filled="!edit"
                        v-model="empresa"
                        :rules="[rules.required]"
                        :items="empresas_select"
                        label="Empresa"
                        validate-on-blur
                      ></v-select>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-select
                        :readonly="!edit"
                        :filled="!edit"
                        v-model="area"
                        :rules="[rules.required]"
                        :items="areas"
                        label="Área"
                        @change="area_reporta = area"
                        validate-on-blur
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row >
                    <v-col sm="3" class="d-flex align-center body-1">
                      Reporta a
                    </v-col>
                    <v-col sm="3">
                      <v-select
                        :readonly="!edit"
                        :filled="!edit"
                        v-model="area_reporta"
                        :items="areas"
                        label="Área"
                        :clearable="edit"
                      ></v-select>
                    </v-col>
                    <v-col sm="6">
                      <v-select
                        :readonly="!edit"
                        :filled="!edit"
                        v-model="puesto"
                        :items="puestos_x_area"
                        label="Puesto"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12" sm="6">
                      <v-textarea
                        :readonly="!edit"
                        :filled="!edit"
                        v-model.trim="editedItem.descripcion"
                        label="Descripción"
                        :auto-grow="$vuetify.breakpoint.xs"
                        rows="3"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-textarea
                        :readonly="!edit"
                        :filled="!edit"
                        v-model.trim="editedItem.mision"
                        label="Misión"
                        :auto-grow="$vuetify.breakpoint.xs"
                        rows="3"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row :no-gutters="$vuetify.breakpoint.xs">
                    <v-col cols="12" sm="6">
                      <v-card>
                        <TablaFunciones :view="!edit" />
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-card>
                        <TablaRequisitos :view="!edit" />
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-select
                    :readonly="!edit"
                    :filled="!edit"
                    v-model="etapas_seleccionadas"
                    :items="etapas"
                    attach
                    chips
                    label="Etapas"
                    class="mt-6"
                    multiple
                  ></v-select>

                  <v-switch
                    :readonly="!edit"
                    :inset="!edit"
                    v-model="editedItem.inhabilitado"
                    label="Inhabilitado"
                  ></v-switch>

                  <v-alert
                    v-if="error"
                    border="left"
                    dense
                    text
                    type="error"
                    class="text-center mx-8"
                  >
                    {{ error_message }}
                  </v-alert>
                </v-form>
              </v-card-text>
              <v-card-actions class="d-flex justify-end pb-4">
                <v-btn v-if="!edit" color="accent" @click="dialog = false">Volver</v-btn>
                <div v-else>
                  <v-btn
                    color="error"
                    class="mr-4"
                    :disabled="load"
                    @click="dialog = false"
                  >
                    Cancelar
                  </v-btn>
                  <Confirmar v-if="edit" :loading="load" @action="save"/>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          color="info"
          @click="editItem(item)"
        >
          fas fa-eye
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-alert
          class="mx-auto my-3"
          max-width="400"
          border="left"
          dense
          text
          type="warning"
        >
          No hay datos para los filtros seleccionados
        </v-alert>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { db } from '../../firebase'
import { mapState, mapActions } from 'vuex'
import LimpiarFiltro from '../../components/utils/LimpiarFiltro'
import TablaFunciones from '../../components/tablas/TablaFunciones'
import TablaRequisitos from '../../components/tablas/TablaRequisitos'
import Confirmar from '../../components/utils/Confirmar.vue'

export default {
  data() {
    return {
      panel: 0,
      dialog: false,
      edit: true,
      load: false,
      error: false,
      error_message: '',

      // selects de edicion/creacion
      empresa: '',
      area: '',
      area_reporta: '',
      puesto: '',
      // -------

      headers: [
        { text: 'Nombre', value: 'nombre'},
        { text: 'Empresa', value: 'empresa.text' },
        { text: 'Área', value: 'area.text' },
        { text: 'Detalle', value: 'actions', align: 'center', sortable: false },
      ],
      puestos: [],
      puestos_x_area: [],
      areas: [],
      etapas: [],
      etapas_seleccionadas: [],
      rules: { required: value => !!value || 'Campo requerido' },
      editedIndex: -1,
      editedItem: {
        id: '',
        nombre: '',
        empresa: {
          text: '',
          value: 0,
        },
        area: {
          text: '',
          value: 0,
        },
        descripcion: '',
        mision: '',
        funciones: [],
        etapas: [],
        requisitos: [],
        reporta: {
          area: '',
          puesto: '',
        },
        inhabilitado: false,
      },
      defaultItem: {
        id: '',
        nombre: '',
        empresa: {
          text: '',
          value: 0,
        },
        area: {
          text: '',
          value: 0,
        },
        descripcion: '',
        mision: '',
        funciones: [],
        etapas: [],
        requisitos: [],
        reporta: {
          area: '',
          puesto: '',
        },
        inhabilitado: false,
      },
    }
  },
  async created() {
    await db.collection('etapas').where('inhabilitado', '==', false).get()
      .then((query) => {
        query.forEach((doc) => {
          this.etapas.push({
            text: doc.data().nombre,
            value: doc.id,
          })
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  components: {
    LimpiarFiltro, TablaFunciones, TablaRequisitos, Confirmar
  },
  computed: {
    ...mapState(['filtro', 'empresas_select']),
    ...mapState('funciones', ['funciones']),
    ...mapState('requisitos', ['requisitos']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Puesto' : 'Editar Puesto'
    },
  },
  watch: {
    dialog (val) {
      if (val) {
        if (this.editedIndex === -1) {
          this.set_funciones([])
          this.set_requisitos([])
        }
      } else {
        this.close()
      }
    },
    empresa (val) {
      this.get_areas(val)
    },
    area_reporta(val) {
      this.get_puestos_x_area(val)
    },
  },
  methods: {
    ...mapActions('funciones', ['set_funciones', 'add_funcion']),
    ...mapActions('requisitos', ['set_requisitos', 'add_requisito']),
    async get_puestos(filtro) {
      this.load = true
      this.puestos = []

      let response = null
      
      if (filtro.empresa === null || filtro.empresa === '') {
        if (filtro.area === null || filtro.area === '') {
          await db.collection('puestos').where('inhabilitado', '==', filtro.inhabilitado).get()
            .then((res) => {
              response = res
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          await db.collection('puestos').where('inhabilitado', '==', filtro.inhabilitado).where('area.value', '==', filtro.area).get()
            .then((res) => {
              response = res
            })
            .catch((error) => {
              console.log(error)
            })
        }
      } else {
        if (filtro.area === null || filtro.area === '') {
          await db.collection('puestos').where('inhabilitado', '==', filtro.inhabilitado).where('empresa.value', '==', filtro.empresa).get()
            .then((res) => {
              response = res
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          await db.collection('puestos').where('inhabilitado', '==', filtro.inhabilitado).where('empresa.value', '==', filtro.empresa).where('area.value', '==', filtro.area).get()
            .then((res) => {
              response = res
            })
            .catch((error) => {
              console.log(error)
            })
        }
      }
      
      response.forEach((doc) => {
        let puesto = doc.data()
        puesto.id = doc.id
        this.puestos.push(puesto)
      })

      this.load = false
      this.puestos = this.puestos.filter(puesto => this.removeAccents(puesto.nombre).indexOf(this.removeAccents(filtro.nombre)) >= 0)

    },

    async save () {
      if (this.$refs.form.validate()) {

        if (this.funciones.length === 0) {
          this.error = true
          this.error_message = 'Debe agregar por lo menos una función principal'
          if (this.requisitos.length === 0) {
            this.error_message = 'Debe agregar por lo menos un requisito y una función principal'
          }
          return
        }
        if (this.requisitos.length === 0) {
          this.error = true
          this.error_message = 'Debe agregar por lo menos un requisito'
          return
        }
        if (this.areas.find(a => a.value === this.area) === undefined) {
          this.error = true
          this.error_message = 'Debe seleccionar un área'
          return
        }

        this.load = true

        const puesto = {
          area: {
            value: this.area,
            text: this.areas.find(are => are.value === this.area).text
          },
          descripcion: this.editedItem.descripcion,
          empresa: {
            value: this.empresa,
            text: this.empresas_select.find(emp => emp.value === this.empresa).text
          },
          etapas: this.etapas_seleccionadas,
          funciones: this.funciones,
          inhabilitado: this.editedItem.inhabilitado,
          mision: this.editedItem.mision,
          nombre: this.editedItem.nombre,
          reporta: {
            area: this.area_reporta,
            puesto: this.puesto,
          },
          requisitos: this.requisitos,
        }

        if (this.editedIndex > -1) {
          // edicion
          await db.collection('puestos').doc(this.editedItem.id).update(puesto)
            .then(() => {
              puesto.id = this.editedItem.id
              Object.assign(this.puestos[this.editedIndex], puesto)
            })
            .catch((error) => {
              console.error(error)
            })
            
        } else {
          // creacion
          await db.collection('puestos').add(puesto)
            .then((docRef) => {
              puesto.id = docRef.id
              this.puestos.push(puesto)
            })
            .catch((error) => {
              console.error(error)
            })
        }

        this.load = false
        this.dialog = false
      }
    },

    async get_areas(empresa) {
      this.areas = []
      this.puestos_x_area = []

      if (empresa !== null && empresa != '') {
        await db.collection('areas').where('inhabilitado', '==', false).where('empresa', '==', empresa).get()
          .then((query) => {
            query.forEach((doc) => {
              this.areas.push({
                text: doc.data().abreviacion,
                value: doc.id,
              })
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },

    async get_puestos_x_area(area) {
      this.puestos_x_area = []

      if (area !== null && area != '') {
        await db.collection('puestos').where('inhabilitado', '==', false).where('area.value', '==', area).get()
          .then((query) => {
            query.forEach((doc) => {
              this.puestos_x_area.push({
                text: doc.data().nombre,
                value: doc.id,
              })
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },

    editItem (item) {
      this.edit = false
      this.editedIndex = this.puestos.indexOf(item)
      this.editedItem = Object.assign({}, item)

      this.empresa = item.empresa.value
      this.area = item.area.value
      this.area_reporta = item.reporta.area
      this.puesto = item.reporta.puesto

      this.set_funciones([])
      this.set_requisitos([])

      this.editedItem.funciones.forEach((funcion) => {
        this.add_funcion(funcion)
      })
      this.editedItem.requisitos.forEach((requisito) => {
        this.add_requisito(requisito)
      })
      this.editedItem.etapas.forEach((etapa) => {
        this.etapas_seleccionadas.push(etapa)
      })
      this.dialog = true
    },
    
    async close () {
      await new Promise(resolve => setTimeout(resolve, 200))
      this.edit = true
      this.error = false
      this.$nextTick(() => {
        this.areas = []
        this.puestos_x_area = []

        this.empresa = ''
        this.area = ''
        this.area_reporta = ''
        this.puesto = ''

        this.set_funciones([])
        this.set_requisitos([])
        this.etapas_seleccionadas = []
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.form.$el.scrollIntoView(true)
      this.$refs.form.resetValidation()
    },
    removeAccents (str) {
      return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    }
  }
}
</script>